





















































































































import { Component, Vue } from "vue-property-decorator";
import { authorizationService } from "@/shared/services/AuthorizationService";
import { Login } from "@/shared/dtos/login";
import { MessageService } from "@/shared/services/message-service";

@Component
export default class AuthOtp extends Vue {
  public otp: string = "";
  private secretKey: string = "";
  public qrCode: string | null = null;
  public mensaje: string | null = null;
  public mensajeTipo: string = "info";
  public paso: number = 1;
  public login: Login = new Login();

  created() {
    //this.secretKey = this.$route.params.secretKey;
    this.login.UserName = this.$route.params.UserName;
    this.login.Pwd = this.$route.params.Pwd;
    this.login.sercret_opt = this.$route.params.sercret_opt;
    this.login.Name = this.$route.params.Name;

    this.generarQr();
  }

  async generarQr() {
    try {
      const response = await authorizationService.generateQr(this.login);
      this.secretKey = response.secret;
      this.login.sercret_opt = this.secretKey;
      this.qrCode = `data:image/png;base64,${response.qrCode}`;
    } catch (error) {
      console.error("Error generando QR:", error);
      this.mensaje = "Error al generar el código QR. Inténtelo más tarde.";
      this.mensajeTipo = "error";
    }
  }

  async validarOtp() {
    try {
      if (!this.otp) {
        this.mensaje = "Por favor, ingrese el código OTP.";
        this.mensajeTipo = "warning";
        return;
      }

      const response = await authorizationService.validarOtp({
        secretKey: this.secretKey,
        otp: this.otp,
        UserName: this.login.UserName,
        Pwd: this.login.Pwd,
      });

      if (response.valid) {
        this.mensaje = "Código OTP válido. Autenticación exitosa.";
        this.mensajeTipo = "success";
        setTimeout(() => {
          //response.loginDto
          console.log("response.loginDto", response.loginDto);
          authorizationService.onLogin(response.loginDto);

          if (response.loginDto.Success) {
            this.$router.push("/");
          }
        }, 1000);
      } else {
        this.mensaje = "Código OTP incorrecto. Inténtalo nuevamente.";
        this.mensajeTipo = "error";
      }
    } catch (error) {
      console.error("Error validando OTP:", error);
      this.mensaje = "Error en la validación del código OTP.";
      this.mensajeTipo = "error";
    }
  }
  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.secretKey);
      MessageService.toastsuccesful(
        "Código OTP copiado en el portapapeles. Ahora añadelo manualmente a Google Authenticator."
      );
    } catch (err) {
      console.error("Error al copiar", err);
    }
  }
}
